import "./index.css";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import { hydrate, render } from "react-dom";
import ReactGa from "react-ga4";

const id = "G-SB35ZTPWMD";
ReactGa.initialize(id);

const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  // If already pre-rendered by React Snap
  hydrate(<App />, rootElement);
} else {
  // For client-side rendering fallback
  render(<App />, rootElement);
}
