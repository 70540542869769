import { useState, useEffect, Fragment } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NavBarCP from "../../components/navbar/Navbar";
import HeroScreen from "../Hero/Hero";
import AboutThisCourse from "../AboutThisCourse/AboutThisCourse";
import CourseDuration from "../CourseDuration/CourseDuration";
import BuildRealProjects from "../BuildRealProjects/BuildRealProjects";
import PricingPlan from "../PricePlan/PricingPlans";
import RealWorldProjects from "../RealWorldProjects/RealWorldProjects";
import WhyNextGen from "../Why/Why";
import Contact from "../Contact/Contact";
import { Box } from "@mui/material";

const sections = [
  {
    id: "section1",
    content: <HeroScreen />,
    title: "Home - NextGen Coders Program",
    description:
      "Learn coding from scratch with our NextGen Coders Program. Build real-world projects and master essential skills like JavaScript, React, and more.",
  },
  {
    id: "section2",
    content: <AboutThisCourse />,
    title: "About This Course",
    description:
      "Discover what makes the NextGen Coders Program unique. Learn about our curriculum, teaching methodology, and more.",
  },
  {
    id: "section3",
    content: <CourseDuration />,
    title: "Course Duration",
    description:
      "Plan your learning journey with a flexible course timeline designed for beginners and aspiring developers.",
  },
  {
    id: "section4",
    content: <BuildRealProjects />,
    title: "Build Real Projects",
    description:
      "Get hands-on experience with real-world projects and strengthen your coding skills.",
  },
  {
    id: "section5",
    content: <PricingPlan />,
    title: "Pricing Plans",
    description:
      "Affordable pricing plans to kickstart your journey into coding and software development.",
  },
  {
    id: "section6",
    content: <RealWorldProjects />,
    title: "Real-World Projects",
    description:
      "See how we teach through real-world applications, providing the tools to solve real problems.",
  },
  {
    id: "section7",
    content: <WhyNextGen />,
    title: "Why Choose NextGen?",
    description:
      "Explore the reasons why NextGen Coders Program is the best choice for aspiring developers.",
  },
  {
    id: "section8",
    content: <Contact />,
    title: "Contact Us",
    description:
      "Get in touch with us for more information about the NextGen Coders Program.",
  },
];

const animationVariants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0, transition: { duration: 0.8 } },
  exit: { opacity: 0, x: -50, transition: { duration: 0.8 } },
};

const Home = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = (e: WheelEvent) => {
      e.preventDefault();

      if (!isScrolling) {
        setIsScrolling(true);

        if (e.deltaY > 0) {
          setCurrentSection((prev) => Math.min(prev + 1, sections.length - 1));
        } else if (e.deltaY < 0) {
          setCurrentSection((prev) => Math.max(prev - 1, 0));
        }

        setTimeout(() => setIsScrolling(false), 800);
      }
    };

    window.addEventListener("wheel", handleScroll, { passive: false });
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [isScrolling, currentSection]);

  const handleSelectedPageChange = (index: number) => {
    setCurrentSection(index);
  };

  return (
    <HelmetProvider>
      <Fragment>
        <Helmet>
          <title>{sections[currentSection].title}</title>
          <meta
            name="description"
            content={sections[currentSection].description}
          />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta charSet="utf-8" />
          <link rel="icon" href="/logo.png" />
          <meta name="theme-color" content="#000000" />
          <meta property="og:title" content={sections[currentSection].title} />
          <meta
            property="og:description"
            content={sections[currentSection].description}
          />
          <meta property="og:image" content="/logo.png" />
          <meta property="og:type" content="website" />
        </Helmet>
        <NavBarCP
          selectedPage={currentSection}
          setSelectedPage={handleSelectedPageChange}
        />
        <Box
          sx={{
            overflow: "hidden",
            position: "relative",
            minHeight: "100vh",
            height: "auto",
            flex: 1,
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={sections[currentSection].id}
              variants={animationVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              style={{
                position: "relative",
                width: "100%",
              }}
            >
              {sections[currentSection].content}
            </motion.div>
          </AnimatePresence>
        </Box>
      </Fragment>
    </HelmetProvider>
  );
};

export default Home;
