import { Box, Typography } from "@mui/material";
import { LAYOUT, useResponsive } from "../../themes/themes";
import Logo from "../../assets/images/real-pro.png";
import HeaderComp from "../../components/header";
import { buildRealProject } from "../../constant";
import { heading, image, logo, subRoot } from "./style";
import {
  FadeScaleAnimation,
  PulseAnimation,
} from "../../components/animations";
import { useAnimationKey } from "../../helper";
import ReactGa from "react-ga4";
import { useEffect } from "react";

const BuildRealProjects = () => {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const animation = useAnimationKey();

  useEffect(() => {
    ReactGa.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <Box sx={{ flex: 1 }}>
      <HeaderComp
        icon="✨"
        title={buildRealProject.title}
        subtitle={buildRealProject.subtitle}
      />
      <Box
        sx={[
          isDesktop ? { display: "flex" } : LAYOUT.columnCCenter,
          { gap: 2, m: 5 },
        ]}
      >
        <Box sx={subRoot(isDesktop)}>
          <Box>
            <Box sx={{ p: 2, gap: 2 }}>
              {buildRealProject.content.map((item: any, index: number) => (
                <Typography key={index} sx={heading}>
                  {item.content}
                </Typography>
              ))}
            </Box>
            <Box
              sx={[
                {
                  p: 1,
                  display: "flex",
                  justifyContent: isDesktop ? "flex-start" : "center",
                },
              ]}
            >
              <PulseAnimation key={animation}>
                {buildRealProject.img.map((item: any, i: number) => (
                  <img
                    key={i}
                    src={item.img}
                    alt="html-image"
                    style={image(isDesktop)}
                  />
                ))}
              </PulseAnimation>
            </Box>
          </Box>
          <Box sx={[LAYOUT.flexColumJCenter, { gap: isDesktop ? 2 : 1 }]}>
            <Box sx={[LAYOUT.flexCCenter]}>
              <Typography sx={[heading, { mt: 2 }]}>
                {buildRealProject.heading}
              </Typography>
            </Box>
            <FadeScaleAnimation key={animation}>
              <img
                src={Logo}
                alt="about"
                style={logo(isDesktop, isTablet, isMobile)}
              />
            </FadeScaleAnimation>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BuildRealProjects;
