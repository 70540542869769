import { Box, Typography } from "@mui/material";
import { LAYOUT, useResponsive } from "../../themes/themes";
import Logo from "../../assets/images/plan.png";
import HeaderComp from "../../components/header";
import { pricingPlan } from "../../constant";
import { content, logo, subRoot } from "./style";
import { RotateAndFadeAnimation } from "../../components/animations";
import { useAnimationKey } from "../../helper";
import { Fragment } from "react/jsx-runtime";
import ReactGa from "react-ga4";
import { useEffect } from "react";

const PricingPlan = () => {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const animation = useAnimationKey();

  useEffect(() => {
    ReactGa.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <Box>
      <HeaderComp
        icon="💰"
        title={pricingPlan.title}
        subtitle={pricingPlan.subTitle}
      />
      <Box
        sx={[
          isDesktop ? { display: "flex" } : LAYOUT.columnCCenter,
          { gap: 2, m: 5 },
        ]}
      >
        <Box sx={subRoot(isDesktop)}>
          <Box sx={[LAYOUT.flexColumnBetween]}>
            <Box sx={{ p: 2, gap: 2 }}>
              {pricingPlan.content.map((item: any, i) => (
                <Fragment key={i}>
                  <Typography sx={content}>
                    {item.title}:- {item.description} ({item.details})
                  </Typography>
                </Fragment>
              ))}
            </Box>
            <Box sx={[{ p: 2, display: "flex" }]}>
              <Typography sx={content}>{pricingPlan.footer}</Typography>
            </Box>
          </Box>
          <Box sx={[LAYOUT.flexColumJCenter, { gap: 2 }]}>
            <RotateAndFadeAnimation key={animation}>
              <img
                src={Logo}
                alt="about"
                style={logo(isDesktop, isTablet, isMobile)}
              />
            </RotateAndFadeAnimation>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PricingPlan;
