import { Box, Button, IconButton, Typography } from "@mui/material";
import { LAYOUT, useResponsive } from "../../themes/themes";
import Logo from "../../assets/images/contact.png";
import HeaderComp from "../../components/header";
import { btnText, button, content, heading, img, input } from "./style";
import { useAnimationKey } from "../../helper";
import { FadeScaleAnimation } from "../../components/animations";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import ReactGa from "react-ga4";
import emailjs from "@emailjs/browser";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Contact = () => {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const animation = useAnimationKey();

  const form: any = useRef();
  const notify = () =>
    toast("Submit Successfully! We will meet shortly.", { type: "success" });
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [formData, setFormData] = useState({
    to_name: "",
    from_name: "",
    message: "",
  });

  useEffect(() => {
    const timestamp = localStorage.getItem("formSubmitTime");
    if (timestamp) {
      const timePassed = Date.now() - parseInt(timestamp, 10);
      if (timePassed < 48 * 60 * 60 * 1000) {
        setIsDisabled(true);
      }
    }
  }, []);

  useEffect(() => {
    ReactGa.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const handleInputChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = (e: any) => {
    e.preventDefault();
    if (
      isDisabled ||
      !formData.to_name ||
      !formData.from_name ||
      !formData.message
    ) {
      return;
    }
    setIsLoading(true);
    emailjs
      .sendForm(
        "service_nz1dnbb",
        "template_w0z3u62",
        form.current,
        "JOMphylOCa_UQxkWz"
      )
      .then(
        () => {
          notify();
          setIsDisabled(true);
          localStorage.setItem("formSubmitTime", Date.now().toString());
          setFormData({
            to_name: "",
            from_name: "",
            message: "",
          });
        },
        (error: any) => {
          console.log("FAILED...", error.text);
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const socialLinks = {
    whatsapp: "https://wa.me/9259457900",
    email: "mailto:nextgencodersprogram@gmail.com",
    instagram: "https://www.instagram.com/nextgencodersprogram",
    twitter: "https://twitter.com/yourpage",
    linkedin: "https://www.linkedin.com/in/nextgencodersprogram/",
  };

  const openLink = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <Box>
      <HeaderComp icon="📩" title=" How to Enroll and Contact Us" />
      <Box
        sx={[
          isDesktop
            ? { display: "flex", justifyContent: "space-around" }
            : LAYOUT.columnCCenter,
          { gap: 2, m: 5 },
        ]}
      >
        <FadeScaleAnimation key={animation}>
          <img
            src={Logo}
            alt="about"
            style={img(isDesktop, isTablet, isMobile)}
          />
        </FadeScaleAnimation>
        <Box sx={content}>
          <form
            ref={form}
            onSubmit={sendEmail}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Box sx={{ p: 2, gap: 2 }}>
              <Typography sx={heading}>Full Name</Typography>
              <input
                placeholder="Enter your full name"
                value={formData.to_name}
                onChange={handleInputChange}
                style={input}
                name="to_name"
                required
              />
              <Typography sx={heading}>Email Address</Typography>
              <input
                placeholder="Enter your email address"
                type="email"
                value={formData.from_name}
                onChange={handleInputChange}
                required
                name="from_name"
                style={input}
              />
              <Typography sx={heading}>Messages</Typography>
              <textarea
                placeholder="Enter your message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
                style={input}
              />
              <Button
                sx={button(isDesktop)}
                type="submit"
                disabled={isDisabled || isLoading}
              >
                <Typography sx={btnText}>Submit</Typography>
              </Button>
            </Box>
          </form>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <IconButton
              onClick={() => openLink(socialLinks.whatsapp)}
              sx={{ color: "#25D366" }} // WhatsApp Green
            >
              <WhatsAppIcon fontSize="medium" />
            </IconButton>
            <IconButton
              onClick={() => openLink(socialLinks.email)}
              sx={{ color: "#D44638" }} // Email Red
            >
              <EmailIcon fontSize="medium" />
            </IconButton>
            <IconButton
              onClick={() => openLink(socialLinks.instagram)}
              sx={{ color: "#C13584" }} // Instagram Gradient Pink
            >
              <InstagramIcon fontSize="medium" />
            </IconButton>
            <IconButton
              onClick={() => openLink(socialLinks.twitter)}
              sx={{ color: "#1DA1F2" }} // Twitter Blue
            >
              <TwitterIcon fontSize="medium" />
            </IconButton>
            <IconButton
              onClick={() => openLink(socialLinks.linkedin)}
              sx={{ color: "#0077B5" }} // LinkedIn Blue
            >
              <LinkedInIcon fontSize="medium" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
