import { COLORS } from "../../themes/themes";

export const subRoot = (isDesktop: boolean) => ({
  flexDirection: isDesktop ? "row" : "column",
  gap: 3,
  m: 2,
});

export const contentCtn = (isDesktop: any) => ({
  alignItems: isDesktop ? "flex-start" : "center",
  flexDirection: "column",
  mb: 2,
  p: isDesktop ? 2 : 0,
});

export const heading = (isDesktop: boolean) => ({
  background: COLORS.Sandstone,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontSize: { xs: 20, md: 45 },
  textAlign: isDesktop ? "left" : "center",
});

export const star = {
  background: "#FFD700",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};

export const subHeading = (isDesktop: boolean) => ({
  background: COLORS.Sandstone,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontSize: { xs: 20, md: 30 },
  mt: 2,
  mb: 2,
  textAlign: isDesktop ? "left" : "center",
});

export const mapContent = (isDesktop: boolean) => ({
  background: COLORS.Sandstone,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontSize: 16,
  width: isDesktop ? 400 : undefined,
});

export const button = (isDesktop: boolean) => ({
  background: COLORS.SemiTransparentWhite,
  padding: isDesktop ? "10px 20px" : "5px 15px",
  borderRadius: "20px",
  mt: 3,
  width: "300px",
  transition: "background 0.3s ease, transform 0.2s ease",
  "&:hover": {
    background: COLORS.GREY,
    transform: "scale(1.05)",
  },
});

export const buttonText = {
  background: COLORS.Crimson,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textTransform: "capitalize",
  fontWeight: "600",
  fontSize: { xs: 16, md: 20 },
};

export const logo = (isDesktop: boolean, isTablet: boolean) => ({
  objectFit: "contain" as "contain",
  width: isDesktop ? 600 : isTablet ? 400 : 250,
});
