import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { LAYOUT, useResponsive } from "../../themes/themes";
import { Avatar } from "@mui/material";
import LogoImg from "../../assets/images/logo.png";
import * as STYLES from "./styles";
import ReactGa from "react-ga4";

const pages = [
  { label: "Home", href: "/" },
  { label: "About", href: "#about" },
  { label: "Course Duration", href: "#course-duration" },
  { label: "Project Opportunities", href: "#project-opportunities" },
  { label: "Pricing Plans", href: "#pricing-plans" },
  { label: "Real World Projects", href: "#real-world-projects" },
  { label: "Why NexGenCoders Program?", href: "#why-nextgencoders-program" },
  { label: "Contact Us", href: "#contact-us" },
  { label: "Join Now", href: "https://academy-portal-web.vercel.app/" },
];

function NavBarCP({ selectedPage, setSelectedPage }: any) {
  const { isMobile, isDesktop } = useResponsive();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavClick = (index: any) => {
    setSelectedPage(index);
    handleCloseNavMenu();

    ReactGa.event({
      category: "Navigation",
      action: "Click Nav Item",
      label: pages[index].label,
    });
  };

  React.useEffect(() => {}, [selectedPage]);

  return (
    <AppBar position="static" sx={STYLES.root}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {isDesktop && (
            <Avatar src={LogoImg} alt="logo" sx={{ width: 100, height: 100 }} />
          )}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="open navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              MenuListProps={{ disablePadding: true }}
              sx={[
                {
                  display: { md: "flex" },
                  justifyItems: "flex-end",
                  alignItems: "flex-end",
                },
              ]}
            >
              {pages.map((page, index) => (
                <MenuItem
                  key={page.label}
                  onClick={() => handleNavClick(index)}
                >
                  <Typography
                    component="a"
                    color="black"
                    href={page.href}
                    fontSize={isMobile ? 13 : 20}
                    textAlign="center"
                    fontFamily="Poppins, sans-serif"
                    style={{
                      textDecoration:
                        selectedPage === index ? "underline" : "none",
                    }}
                  >
                    {page.label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={STYLES.heading(isMobile)}
          >
            NextGen Coders Program
          </Typography>
          <Box sx={[LAYOUT.flexJAEnd, { flexGrow: 1 }]}></Box>
          <Box sx={{ flexGrow: 0 }}>
            <Box sx={[LAYOUT.flexJAEnd, STYLES.buttonCtn]}>
              {pages.map((page, index) => (
                <Button
                  key={page.label}
                  href={page.href}
                  onClick={() => handleNavClick(index)}
                  sx={{
                    ...STYLES.buttonLabel,
                    textDecoration:
                      selectedPage === index ? "underline" : "none",
                    cursor: "pointer",
                  }}
                >
                  {page.label}
                </Button>
              ))}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBarCP;
